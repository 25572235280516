@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .header {
    background-color: mat.get-theme-color($theme, neutral, 92);
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
