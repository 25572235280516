@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .task-item {
    background-color: mat.get-theme-color($theme, neutral, 98);
    max-width: calc(100% - 32px);
  }

  .task-item-selected {
    background-color: mat.get-theme-color($theme, neutral, 87);
    max-width: calc(100% - 32px);
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
