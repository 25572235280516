// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
//@use '@angular/material' as mat;
@use 'sass:map';
@use 'm3-theme';
//
//@include mat.core();
//
//html {
//  // Apply the light theme by default
//  @include mat.core-theme(m3-theme.$light-theme);
//  @include mat.button-theme(m3-theme.$light-theme);
//}

@use '@angular/material' as mat;
@use 'header-theme' as header;
@use 'task-item-theme' as task-item;

@include mat.core();


html {
  // Emit theme-dependent styles for common features used across multiple components.
  @include mat.core-theme(m3-theme.$light-theme);

  // Emit styles for MatButton based on `m3-theme.$dark-theme`. Because the configuration
  // passed to `define-theme` omits typography, `button-theme` will not
  // emit any typography styles.
  // Include the theme mixins for other components you use here.
  @include mat.menu-theme(m3-theme.$light-theme);
  @include mat.sidenav-theme(m3-theme.$light-theme);
  @include mat.form-field-theme(m3-theme.$light-theme);
  @include mat.button-theme(m3-theme.$light-theme);
  @include mat.icon-button-theme(m3-theme.$light-theme);
  @include mat.icon-theme(m3-theme.$light-theme);
  @include mat.icon-button-color(m3-theme.$light-theme);
  @include mat.fab-theme(m3-theme.$dark-theme);
  @include header.theme(m3-theme.$light-theme);
  @include task-item.theme(m3-theme.$light-theme);
  @include mat.datepicker-color(m3-theme.$light-theme);
  @include mat.chips-base(m3-theme.$light-theme);
  @include mat.menu-color(m3-theme.$light-theme)
}

// Apply custom global icon color using the theming system
.mat-icon {
  color: mat.get-theme-color(m3-theme.$dark-theme, primary, 70);
}

mat-form-field {
  --mdc-outlined-text-field-outline-color: rgb(204, 204, 204);
  mat-label {
    color: rgb(153, 153, 153);
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  --mdc-outlined-text-field-label-text-color: #333;
}

.mat-mdc-text-field-wrapper {
  background: white;
}
