.light {
  --md-sys-color-primary: var(--dropdown-background);
  --md-sys-color-surface-tint: rgb(122 89 0);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(255 201 83);
  --md-sys-color-on-primary-container: rgb(80 57 0);
  --md-sys-color-secondary: rgb(115 91 41);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(255 224 167);
  --md-sys-color-on-secondary-container: rgb(91 69 21);
  --md-sys-color-tertiary: rgb(122 89 0);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 201 83);
  --md-sys-color-on-tertiary-container: rgb(80 57 0);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(255 248 242);
  --md-sys-color-on-background: rgb(32 27 18);
  --md-sys-color-surface: rgb(253 248 247);
  --md-sys-color-on-surface: rgb(28 27 27);
  --md-sys-color-surface-variant: rgb(234 225 214);
  --md-sys-color-on-surface-variant: rgb(75 70 62);
  --md-sys-color-outline: rgb(124 118 109);
  --md-sys-color-outline-variant: rgb(205 197 187);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(49 48 47);
  --md-sys-color-inverse-on-surface: rgb(244 240 238);
  --md-sys-color-inverse-primary: rgb(247 190 56);
  --md-sys-color-primary-fixed: rgb(255 222 161);
  --md-sys-color-on-primary-fixed: rgb(38 25 0);
  --md-sys-color-primary-fixed-dim: rgb(247 190 56);
  --md-sys-color-on-primary-fixed-variant: rgb(92 66 0);
  --md-sys-color-secondary-fixed: rgb(255 222 161);
  --md-sys-color-on-secondary-fixed: rgb(38 25 0);
  --md-sys-color-secondary-fixed-dim: rgb(226 194 134);
  --md-sys-color-on-secondary-fixed-variant: rgb(89 67 20);
  --md-sys-color-tertiary-fixed: rgb(255 222 161);
  --md-sys-color-on-tertiary-fixed: rgb(38 25 0);
  --md-sys-color-tertiary-fixed-dim: rgb(247 190 56);
  --md-sys-color-on-tertiary-fixed-variant: rgb(92 66 0);
  --md-sys-color-surface-dim: rgb(221 217 216);
  --md-sys-color-surface-bright: rgb(253 248 247);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(247 243 241);
  --md-sys-color-surface-container: rgb(241 237 235);
  --md-sys-color-surface-container-high: rgb(236 231 230);
  --md-sys-color-surface-container-highest: rgb(230 226 224);
}
