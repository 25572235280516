@import "./assets/scss/app.scss";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.custom-loader-local {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 3px;
  background: radial-gradient(farthest-side, #FBC13B 95%, #0000) 50% 0/12px 12px no-repeat,
  radial-gradient(farthest-side, #0000 calc(100% - 5px), #FBC13B calc(100% - 4px)) content-box;
  animation: s6 2s infinite;
  z-index: 2000;
}

@keyframes s6 {
  to {
    transform: rotate(1turn)
  }
}

.custom-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 3px;
  background: radial-gradient(farthest-side, #FBC13B 95%, #0000) 50% 0/12px 12px no-repeat,
  radial-gradient(farthest-side, #0000 calc(100% - 5px), #FBC13B calc(100% - 4px)) content-box;
  animation: s6 2s infinite;
  z-index: 2000;
}

@keyframes s6 {
  to {
    transform: rotate(1turn)
  }
}

.custom-loader-img-local {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 3px;
  background: url('assets/svg/mosquito.svg') 0 0;
  animation: s6 2s infinite;
  z-index: 2000;
}

.custom-loader-img {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 3px;
  background: url('assets/svg/mosquito.svg') 0 0;
  animation: s6 2s infinite;
  z-index: 2000;
}
