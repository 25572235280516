:root {
  --status-pending: #c9daf8;
  --status-pending-background: rgba(201, 218, 248, 0.6);
  --status-pending-text: black;
  --status-cancelled: #dc3545;
  --status-cancelled-background: rgba(220, 53, 69, 0.6);
  --status-cancelled-text: white;
  --status-completed-approved-0: #59bf20;
  --status-completed-approved-0-background: rgba(89, 191, 32, 0.6);
  --status-completed-approved-0-text: white;
  --status-completed-approved-1: #bad810;
  --status-completed-approved-1-background: rgba(186, 216, 16, 0.6);
  --status-completed-approved-1-text: white;
  --status-completed-approved-2: #e1e30d;
  --status-completed-approved-2-background: rgba(225, 227, 13, 0.6);
  --status-completed-approved-2-text: black;
  --status-completed-approved-about-to-expire: #fbe526;
  --status-completed-approved-about-to-expire-background: #fbe526;
  --status-completed-approved-about-to-expire-text: black;
  --status-completed-approved-expired: #f9a003;
  --status-completed-approved-expired-background: rgba(249, 160, 3, 0.6);
  --status-completed-approved-expired-text: white;
  --status-progress: #3d85c6;
  --status-progress-background: rgba(61, 133, 198, 0.6);
  --status-progress-text: white;
  --status-no-task: #f9a003;
  --status-no-task-background: rgba(249, 160, 3, 0.6);
  --status-no-task-text: white;
  --flag-orange: #ffab57;
  --flag-yellow: #ffe576;
  --flag-purple: #e0cdfc;
  --flag-total: #000000;
}
